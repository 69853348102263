export class ExponentialBackoff {
  private readonly minDelay: number
  private readonly maxDelay: number
  private readonly factor: number
  private readonly jitter: boolean
  private attempts: number
  private readonly maxAttempts: number

  constructor({minDelay = 1000, maxDelay = 60000, factor = 1.5, jitter = true, maxAttempts = 10} = {}) {
    this.minDelay = minDelay
    this.maxDelay = maxDelay
    this.factor = factor
    this.jitter = jitter
    this.maxAttempts = maxAttempts
    this.attempts = 0
  }

  next(): number {
    if (this.attempts >= this.maxAttempts) {
      return -1
    }

    // Calculate base delay with exponential backoff
    const baseDelay = Math.min(this.minDelay * this.factor ** this.attempts, this.maxDelay)

    // Add jitter if enabled (±25% of base delay)
    const delay = this.jitter ? baseDelay * (0.75 + Math.random() * 0.5) : baseDelay

    // Round to nearest 100ms for cleaner numbers
    const roundedDelay = Math.round(delay / 100) * 100

    this.attempts++
    return roundedDelay
  }

  reset(): void {
    this.attempts = 0
  }

  getCurrentAttempts(): number {
    return this.attempts
  }

  getRemainingAttempts(): number {
    return Math.max(0, this.maxAttempts - this.attempts)
  }
}
